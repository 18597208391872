import React from "react";
import { useSelector } from 'react-redux';
import { Loader } from "./loader";
import { SigninFlowChildProps } from "../store/user/types";
import { useUserSlice } from "store/user/reducer";
import { selectAppRegistrations } from "store/app/select";
import { selectFormLoading } from "store/user/select";
import UserInfo from "./userInfo";


interface LogoutFormProps extends SigninFlowChildProps {}

export function LogoutForm({}: LogoutFormProps) {

  const { userLogout } = useUserSlice();
  const appRegisters = useSelector(selectAppRegistrations);
  const formLoading = useSelector(selectFormLoading);

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    userLogout(appRegisters);
  }

  return (
    <div className="TRS-widget logoutForm" style={{position: 'relative'}}>
      
      <form className={'logout-form'} 
        action="" method="POST" noValidate onSubmit={submitForm}>

        <div>
          <UserInfo />
        </div>

        <div className="form-group text-center mt-3">
          <button type="submit" className="btn btn-primary">
            Sign Out
          </button>
        </div>

      </form>

      <Loader loading={formLoading} exclass="dark" />

    </div>
  );
}


export default LogoutForm;
