import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import RatingStar from './ratingStar';
import { Loader } from "./loader";
import { RatingState } from "../store/ratings/types";
import { AppState } from "../store/app/types";
import classNames from 'classnames';
import { useAppSlice } from "store/app/reducer";
import { selectCommentSuccess, selectRatingFormLoading, selectRatingScore, selectRatingSuccess } from "store/ratings/select";
import { setScore, useRatingsSlice } from "store/ratings/reducer";
import { selectAppRegistrationData } from "store/app/select";


export interface RatingFormProps {
  postId: number | null;
  postTitle?: string;
  type: "comment" | "rating" | "combined";
  withCloser: boolean;
  commentType?: "comment" | "question"
  subjectChoice?: boolean | string
  episodeThumbnail?: string
}


export function RatingForm({ postId, subjectChoice, type, commentType: initCommentType, withCloser, episodeThumbnail, postTitle }: RatingFormProps) {

  const subjectChoiceVal = typeof subjectChoice === 'string' 
    ? subjectChoice?.toLowerCase() === 'false' ? false : true
    : !!subjectChoice;
    
  const hasSubjectChoice: boolean = subjectChoiceVal
    ? (!!postId && type === 'comment')
    : false;

  const mainRef = useRef<HTMLDivElement>(null);
  const [ commentType, setCommentType ] = useState(initCommentType);
  const [ subjectPostId, setSubjectPostId ] = useState(postId);
  const [ subjectChosen, setSubjectChosen ] = useState(!hasSubjectChoice);
  const [ comment, setComment ] = useState('');
  const [ submitted, setSubmitted ] = useState(false);
  const [ texts ] = useState(getTexts());

  //const ratingState = props.ratingState;
  //const appState = props.appState;

  const { register } = useAppSlice();
  const { ratingSet, setRatingSuccess, setCommentSuccess } = useRatingsSlice();

  const rating_success = useSelector(selectRatingSuccess);
  const comment_success = useSelector(selectCommentSuccess);
  const app_registration_data = useSelector(selectAppRegistrationData);
  const score = useSelector(selectRatingScore);
  const form_loading = useSelector(selectRatingFormLoading);
  

  useEffect(() => {

    if (postId) {
      register({
        type: 'rating',
        post_id: parseInt(postId as any),
        count: 1,
      })
    }

    window.addEventListener('TRS.data-init', setScoreFromRegData);

    return function() {
      window.removeEventListener('TRS.data-init', setScoreFromRegData);
    }
  // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (withCloser) {
      if (mainRef.current) {
        const modal = mainRef.current.closest(".modal");
        const $ = ((window as any).jQuery as any);        
        if (modal && $) {

          const ff = () => resetState();

          $(modal).on('hide.bs.modal', ff);

          return function() {
            $(modal).off('hide.bs.modal', ff);
          }
        }
      }
    }

    function resetState() {
      if (comment_success) {
        setComment('');
      }
      setRatingSuccess(false)
      setCommentSuccess(false);
    }  

  }, [ mainRef, withCloser, comment_success, setRatingSuccess, setCommentSuccess ]);


  function setScoreFromRegData() {
    // find a reg data that matches my post_id
    const regdata = app_registration_data.find(rd => rd.type === 'rating' && rd.post_id === postId);
    // set my score, if I have one
    if (regdata?.type === 'rating' && regdata?.data?.myrating) {
      setScore(regdata.data.myrating.score || 0)
    }
  }

  function changeScore(e: React.MouseEvent, score: number) {
    if (postId) {
      setSubmitted(false);
      console.log(postId, score);
      ratingSet({ 
        post_id: postId,
        data: {
          score: score
        }
      })
    }
  }

  function submitForm(e: React.FormEvent) {
    e.preventDefault();
    setSubmitted(true);

    const fdata: { score?: number, comment?: string, comment_type?: "comment" | "question" } = {};
    if (type === 'rating' || type === 'combined') {
      fdata.score = score;
    }
    if (type === 'comment' || type === 'combined') {
      if (commentType === 'question') {
        fdata.comment_type = 'question';
      } else if (commentType === 'comment') {
        fdata.comment_type = 'comment';
      }
      fdata.comment = comment.trim();
    }

    ratingSet({
      post_id: postId,
      data: fdata
    })
  }


  let commentSection:React.ReactElement | null = null;
  let submitSection:React.ReactElement | null = null;
  let subjectChoiceSection:React.ReactElement | null = null;
  if (type === "comment" || type === "combined") {
    if (commentType === 'comment') {
      commentSection = (
        <div>
  
          <h5 className="mb-2 text-center">
            <small>Enter your Comment:</small>
            <button type="button" className="btn btn-link m-0 p-0" onClick={() => setCommentType('question')}
              >(ask a question instead)</button>
          </h5>
  
          <div className="form-group">
            <textarea rows={6} className="form-control"
              onChange={e => setComment(e.target.value)} value={comment}></textarea>
          </div>
        </div>      
      )  
    } else if (commentType === 'question') {
        commentSection = (
          <div>
    
            <h5 className="mb-2 text-center">
              <small>Enter your Question:</small>
              <button type="button" className="btn btn-link m-0 p-0" onClick={() => setCommentType('comment')}
                >(leave a comment instead)</button>
            </h5>
    
            <div className="form-group">
              <textarea rows={6} className="form-control"
                onChange={e => setComment(e.target.value)} value={comment}></textarea>
            </div>
          </div>      
        )  
    } else {
      commentSection = (
        <div>
          <h4 className="text-center">Choose Action:</h4>
          <div className="form-group row justify-content-center align-items-center mb-4">
            <div className="col-md text-center my-3">
              <button className="btn btn-secondary btn-lg"
                onClick={() => setCommentType('question')}>Ask a Question</button>
            </div>
            <div className="col-md text-center my-3">
              <button className="btn btn-secondary btn-lg"
                onClick={() => setCommentType('comment')}>Leave a Comment</button>
            </div>
          </div>
        </div>      
      )  
    }

    submitSection = (
      <div className={classNames("form-group text-center", { 'd-none': !commentType })}>
        <button type="submit" className="btn btn-primary btn-lg" disabled={(!commentType)}>
          Submit {commentType === 'question' ? 'Question' : 'Comment'}
        </button>
      </div>
    )
  }

  if (hasSubjectChoice) {

    const subjectChoiceText = commentType === 'question' 
      ? 'Question' 
      : commentType === 'comment' ? 'Comment' : 'Comment / Question';

    let episodeButton:React.ReactElement | null = null;
    if (episodeThumbnail) {
      episodeButton = (
        <button className="btn btn-secondary d-block btn-figure" style={{'whiteSpace': 'normal'}}
          onClick={() => { setSubjectPostId(postId); setSubjectChosen(true)} }>
          <figure>
            <img src={episodeThumbnail} className="img-fluid d-block" alt={postTitle} />
            <figcaption>
              <small>{postTitle}</small>
            </figcaption>
          </figure>
        </button>  
      )
    } else {
      episodeButton = (
        <button className="btn btn-secondary btn-lg" style={{'whiteSpace': 'normal'}}
          onClick={() => { setSubjectPostId(postId); setSubjectChosen(true)} }>
          Episode:<br />
          <small>{postTitle}</small>
        </button>
      )  
    }

    subjectChoiceSection = (
      <div>
        <h4 className="text-center">What is the subject of your {subjectChoiceText}?</h4>
        <div className="form-group row justify-content-center align-items-center mb-4">
          <div className="col-md text-center my-3">
          
            {episodeButton}

          </div>
          <div className="col-md text-center my-3">
            <button className="btn btn-secondary text-center" style={{'whiteSpace': 'normal'}}
              onClick={() => { setSubjectPostId(null); setSubjectChosen(true)}}>
                The Ruddle Show Website:<br />
                General Comment on Anything or Everything!
              </button>
          </div>
        </div>
      </div>
    )
  }

  let actionTxt = "";
  if (type === "comment") {
    if (commentType === 'question') {
      actionTxt = "Question";      
    } else if (commentType === 'comment') {
      actionTxt = "Comment";
    } else {
      actionTxt = "Comment or Question";
    }
  } else if (type === "rating") {
    actionTxt = "Rating";
  } else {
    if (commentType === 'question') {
      // questions never have ratings
      actionTxt = "Question"
    } else if (commentType === 'comment') {
      actionTxt = "Rating and Comment";
    } else {
      actionTxt = "Rating and Comment / Question";
    }
  }

  let thankyouHTML: string;
  if (type !== 'rating' && commentType === 'question') {
    thankyouHTML = texts.thankyou_question;
  } else if (type !== 'rating' && commentType === 'comment') {
    thankyouHTML = texts.thankyou_comment;
  } else {
    thankyouHTML = texts.thankyou_rating;
  }

  return (
    <div className="TRS-widget TRS-ratingForm" style={{position: 'relative'}} 
      ref={mainRef}>

      <h4 className="text-center">

        <small>{actionTxt}</small>

        <small className={classNames({ 
          'd-none': hasSubjectChoice && !subjectChosen
          })}
          >{postTitle ? ' for:' : ''}</small><br />

        <span className={classNames({ 
          'd-none': hasSubjectChoice && !subjectChosen
          })}
          >{!subjectPostId ? `The Ruddle Show` : `${postTitle ? postTitle : ''}`}</span>
        
        <div className={classNames({ 'd-none': !hasSubjectChoice || !subjectChosen })}>
          <button type="button" className="btn btn-link m-0 p-0" 
            onClick={() => setSubjectChosen(false)}
            >(change)</button>
        </div>
      </h4>

      <div className={classNames('text-center mt-4', {
          'd-none': subjectChosen
          })}> 
          {subjectChoiceSection}
      </div>

      <form onSubmit={submitForm}>

        <div className="d-flex justify-content-center">

          <div className={classNames({
            'd-none': ((type === 'comment') || (type !== 'rating' && commentType === 'question'))
            })}>
            <h5 className="mb-2 text-center">
              <small>Select your rating below</small>
            </h5>

            <div className="stars text-center">
              {[1,2,3,4,5].map((i) => (
                <RatingStar key={i} selectScore={changeScore} score={i} selectedScore={score} />
              ))}
            </div>
            <div className={classNames('your-rating text-center', {
              'invisible': !score
              })}>
              Your rating: <span>{score} Stars</span>
            </div>
          </div>
        </div>

        <div className={classNames('mt-4', {
          'd-none': (rating_success && (type === 'rating' || submitted)) || comment_success || !subjectChosen
          })}>
          {commentSection}
          {submitSection}
        </div>

      </form>

      <div className={classNames('mt-4', {
          'd-none': !( (rating_success && (type === 'rating' || submitted)) || comment_success )
          })}>

        <div className="alert alert-info d-flex justify-content-center">
          <div>

            <div dangerouslySetInnerHTML={createMarkup(thankyouHTML)}></div>

            {withCloser ? 
            <p className="text-center mt-4">
              <button className="btn btn-secondary btn-lg" data-dismiss="modal">
                Close
              </button>
            </p>
            : ''}
          </div> 
        </div>

      </div>

      <Loader loading={form_loading} exclass="dark" />

    </div>
  );
}


export default RatingForm;



export function createMarkup(baseText: string, subText?: string) {
  return {
    __html: subText !== undefined ? baseText.replace('{}', subText) : baseText
  };
}


const textsPrefix = 'commentModalTxt_';
let cachedTexts: { [name: string]: string };

export function getTexts(): { [name: string]: string } {

  // cache the results so we only fetch the elements it once
  if (cachedTexts) {
    return cachedTexts;
  }

  let texts: { [name: string]: string } = {};

  [
    'thankyou_rating',
    'thankyou_comment',
    'thankyou_question',
  ].forEach(t => {
    let el = document.getElementById(`${textsPrefix}${t}`);
    if (el) {
      texts[t] = el.innerHTML;
    }
  })

  cachedTexts = texts;

  return cachedTexts;
}