import 'whatwg-fetch'
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
//import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Main from './components/Main';
import LoginForm from './components/loginForm';
import LogoutForm from './components/logoutForm';
import SignupForm from './components/signupForm';
import SigninFlow from './components/signInFlow'
import AccountFlow from './components/account/accountFlow';
import UserInfo from './components/userInfo';
import RatingFlow, { RatingFlowProps } from './components/ratingFlow';
import Poll, { PollProps } from './components/poll';
import PollFlow, { PollFlowProps } from './components/pollFlow';
import SearchPage, { SearchPageProps } from './components/SearchPage';
import PortalFlow, { PortalFlowProps } from './components/members/PortalFlow';
import PortalMainContentFlow, { PortalMainContentFlowProps } from './components/members/PortalMainContentFlow';
import PodcastFlow, { PodcastFlowProps } from './components/members/PodcastFlow';
import MembersFlow, { MembersFlowProps } from './components/members/MembersFlow';
import PostList, { PostListProps } from './components/postList/PostList';
import ShowList, { ShowListProps } from './components/showList/ShowList';
import EntityList, { EntityListProps } from './components/entityList/EntityList';
import PortalWatchedIndicator, { PortalWatchedIndicatorProps } from './components/members/PortalWatchedIndicator';
import SubscribeFlow, { SubscribeFlowProps } from './components/subscribe/SubscribeFlow';
import './index.scss';
import PodcastList from 'components/podcastList/PodcastList';
import ShowFlow, { ShowFlowProps } from './components/members/ShowFlow';


let uid = 0;

function wuid() {
  return 'TRS' + (uid++);
}


const TRS = (window as any).TRS = {
  renderMain,
  renderLoginForm,
  renderLogoutForm,
  renderSignupForm,
  renderSigninFlow,
  renderAccountFlow,
  renderUserInfo,
  renderRatingFlow,
  renderPoll,
  renderPollFlow,
  renderSearchPage,
  renderPortalFlow,
  renderPortalMainContentFlow,
  renderPodcastFlow,
  renderMembersFlow,
  renderPostList,
  renderShowList,
  renderEntityList,
  renderPodcastList,
  renderPortalWatchedIndicator,
  renderSubscribeFlow,
  renderShowFlow,
  wuid,
}


function renderMain(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <Main />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderLoginForm(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <LoginForm changeStep={function(e) {}} setFBMessage={() => {}} />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderSignupForm(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <SignupForm changeStep={function(e) {}} />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderLogoutForm(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <LogoutForm changeStep={function(e) {}} />
      </Provider>
    </React.StrictMode>,
    selector
  );
}



function renderSigninFlow(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <SigninFlow />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderUserInfo(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <UserInfo />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderAccountFlow(selector: string) {
  renderToSelector(
    <React.StrictMode>
      <Provider store={store}>
        <AccountFlow />
      </Provider>
    </React.StrictMode>,
    selector
  );
}


function renderRatingFlow(selector: string) {
  renderToSelectorWithProps<RatingFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <RatingFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderPoll(selector: string) {
  renderToSelectorWithProps<PollProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <Poll {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderPollFlow(selector: string) {
  renderToSelectorWithProps<PollFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PollFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderSearchPage(selector: string) {
  renderToSelectorWithProps<SearchPageProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <SearchPage {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderPortalFlow(selector: string) {
  renderToSelectorWithProps<PortalFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PortalFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}



function renderPortalMainContentFlow(selector: string) {
  renderToSelectorWithProps<PortalMainContentFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PortalMainContentFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderPodcastFlow(selector: string) {
  renderToSelectorWithProps<PodcastFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PodcastFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderShowFlow(selector: string) {
  renderToSelectorWithProps<ShowFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <ShowFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderMembersFlow(selector: string) {
  renderToSelectorWithProps<MembersFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <MembersFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderSubscribeFlow(selector: string) {
  renderToSelectorWithProps<SubscribeFlowProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <SubscribeFlow {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}

function renderPostList(selector: string) {
  renderToSelectorWithProps<PostListProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PostList {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}

function renderShowList(selector: string) {
  renderToSelectorWithProps<ShowListProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <ShowList {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderEntityList(selector: string) {
  renderToSelectorWithProps<EntityListProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <EntityList {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderPodcastList(selector: string) {
  renderToSelectorWithProps<ShowListProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PodcastList {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}

function renderPortalWatchedIndicator(selector: string) {
  renderToSelectorWithProps<PortalWatchedIndicatorProps>((props) => 
    <React.StrictMode>
      <Provider store={store}>
        <PortalWatchedIndicator {...props} />
      </Provider>
    </React.StrictMode>
    , selector
  );
}


function renderToSelector(sfcel: any | any[], selector: string) {
  document.querySelectorAll(selector).forEach((el) => {

    // only elements without the data-trs dataset are rendered to React
    // so we don't re-render elements
    if ('dataset' in el) {
      if ((el as HTMLElement).dataset.trs === "true") {
        return;
      } else {
        (el as HTMLElement).dataset.trs = "true";
      }
    }

    //render(sfcel, el);
    const root = createRoot(el);
    root.render(sfcel);
  })
}


function renderToSelectorWithProps<PType = void>(sfcelFunc: (props: PType) => ReactNode, selector: string) {
  document.querySelectorAll(selector).forEach((el) => {

    // only elements without the data-trs dataset are rendered to React
    // so we don't re-render elements
    if ('dataset' in el) {
      if ((el as HTMLElement).dataset.trs === "true") {
        return;
      } else {
        (el as HTMLElement).dataset.trs = "true";
      }
    }

    const props = gatherProps(el as HTMLElement);

    //render(sfcelFunc(props), el);
    const root = createRoot(el);
    root.render(sfcelFunc(props));
  })
}


function gatherProps(el: HTMLElement) {
  const props: any = {};

  for (let i in el.dataset) {
    if (i === "trs") { continue };
    props[i] = el.dataset[i];
  }

  return props;
}


window.dispatchEvent(new Event('TRS.init'));

export { TRS }
